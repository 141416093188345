<template>
    <div class="">
      <button id="ww" type="button" class="hidden" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
      </button>
      <button id="yy" type="button" class="hidden" data-bs-toggle="modal" data-bs-target="#slotDetailModal">
      </button>
    </div>

    <!-- Modal for date change -->

    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
              Add Slot to {{nameDisplay}}
            </h5>
            <button type="button"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body relative p-4">

    <!-- Non-native datepicker for browser friendliness -->

            <Datepicker v-model="timeToSend" timePicker placeholder="Select Time" />

            <select  v-model="sendOPE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
              <option :value="null">Select OPE</option>
              <option v-for="staff in OPE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
            </select>
            <select  v-model="sendELE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
              <option :value="null">Select ELE</option>
              <option v-for="staff in ELE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
            </select>
              <select  v-model="sendlocation" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option disabled :value="null">Select Location</option>
                <option v-for="location in addresses"  :value="location.id" :key="location.id">{{location.name}}</option>
              </select>

          </div>
          <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button type="button"
              @click="timeEnd"  id="close" class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
              data-bs-dismiss="modal">
              Close
            </button>
            <button type="button"
              @click="timeSend" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
              Add Slot
            </button>
          </div>
        </div>
      </div>
    </div>


    
    <!-- slot infor modal -->

  <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" data-bs-backdrop="static" data-bs-keyboard="false" id="slotDetailModal" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
            <h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
            Slot Details
            </h5>
            <button @click="updatingSlotClose" type="button" id="closeUpdate"
              class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div  class="modal-body relative p-4">
            <div v-if="slotInfo && updatingSlot===false">
              <div class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">Time:</h2>
                <h2 class="text-md font-semibold text-t3-mutedText ml-2">{{slotInfo.start_time}}</h2>
              </div>
              <div class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">Date:</h2>
                <h2 class="text-md font-semibold text-t3-mutedText ml-2">{{new Date(slotInfo.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</h2>
              </div>
              <div class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">Location:</h2>
                <h2 v-if="addresses.find(a => a.id === slotInfo.location)" class="text-md font-semibold text-t3-mutedText ml-2">{{addresses.find(a => a.id === slotInfo.location).name}}</h2>
              </div>
              <div class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">OPE:</h2>
                <h2 v-if="OPE.find(man => man.id === slotInfo.ope)" class="text-md font-semibold text-t3-mutedText ml-2">{{OPE.find(man => man.id === slotInfo.ope).full_name}}</h2>
              </div>
              <div class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">ELE:</h2>
                <h2 v-if="ELE.find(man => man.id === slotInfo.ele)" class="text-md font-semibold text-t3-mutedText ml-2">{{ELE.find(man => man.id === slotInfo.ele).full_name}}</h2>
              </div>
              <div v-if="slotInfo.assessment" class="flex w-full px-3 my-2">
                <h2 class="text-md font-semibold text-t3-t3Light">Customer:</h2>
                <h2 class="text-md font-semibold text-t3-mutedText ml-2">{{slotInfo.assessment.customer}}</h2>
              </div>
            </div>
            <div v-if="slotInfo && updatingSlot===true">
              <select  v-model="sendOPE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option :value="null">Select OPE</option>
                <option v-for="staff in OPE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
              </select>
              <select  v-model="sendELE" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option :value="null">Select ELE</option>
                <option v-for="staff in ELE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
              </select>
              <select  v-model="sendlocation" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                <option disabled :value="null">Select Location</option>
                <option v-for="location in addresses"  :value="location.id" :key="location.id">{{location.name}}</option>
              </select>
            </div>


          </div>
          <div  class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
            <button @click="timeDelete(slotInfo.id) "  v-if="updatingSlot===false && !slotInfo.assessment  && scopeCheck('delete_slot')" class="font-medium p-4 text-center ml-auto mr-2 text-red-600 my-auto">Delete Slot</button>
            <button @click="updatingSlotSwitch "  v-if="updatingSlot===false && (!slotInfo.assessment || slotInfo.assessment.status === 'booked') && scopeCheck('update_slot')" class="font-medium p-4 text-center mr-2 text-blue-600 my-auto">Update Slot</button>
            <button v-if="slotInfo.assessment && updatingSlot===false" @click="viewAssessment(slotInfo.assessment.id)" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
              View Assessment
            </button>
            <button type="button"
              @click="updatingSlotClose" v-if="updatingSlot===true" id="close" class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
              >
              Cancel
            </button>
           
            <button type="button"
              @click="timeUpdate(slotInfo.id) " v-if="updatingSlot===true && (!slotInfo.assessment || slotInfo.assessment.status === 'booked')  " class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
              Update Slot
            </button>
          </div>
        </div>
      </div>
    </div>



  
    <!-- Actual full calendar -->

    <FullCalendar :options="calendarOptions" />

</template>

<script>
  import '@fullcalendar/core/vdom'; // solves problem with Vite
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from '@fullcalendar/vue3';
// import router from '../router'
import ReportServices from '@/services/ReportServices';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Swal from 'sweetalert2';
import { ref, watchEffect } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import router from '../router';
import SlotsServices from "../services/SlotsServices";
export default {
  components: {
    FullCalendar ,
    Datepicker 
 },
  setup() {
        const date = ref(new Date());
        
        return {
            date,
        }
        },
 props:{
  slots:[],
  defoTime:{
    type:String
  }
 },
  data() {
    return {
      
      updatingSlot:false,
      slotInfo:{
      },
      nameDisplay:"",
      timeToSend:"",
      sendlocation:null,
      sendOPE:null,
      sendELE:null,
      calendarOptions: {
          headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay'
  },
          eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        },
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
        initialView: 'dayGridMonth',
        selectable: true,
        eventDisplay:"block",
        eventBorderColor: "transparent",
        defaultTimedEventDuration:this.defoTime,
        events: [],
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        select: this.handleSelect,
        datesSet:this.handleDatesSet
      }
    }
  },
  computed:{
        ...mapState({
            customers: state => state.Users.customers,
            samples: state => state.Samples.samples,
            addresses: state => state.Settings.addresses
        }),
        ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        "ELE",
        "OPE"
      ])
  },
    methods:{
      ...mapActions({			      
        getcustomers: 'Users/getAllCustomers',
            getsamples: 'Samples/getAllSamples',
            getaddresses: 'Settings/getAllAddresses',
        }),
        updatingSlotSwitch(){
          this.updatingSlot=true
          this.sendELE=this.slotInfo.ele
          this.sendOPE=this.slotInfo.ope
          this.sendlocation=this.slotInfo.location
        },
        updatingSlotClose(){
          this.updatingSlot=false
          this.sendELE=null
          this.sendOPE=null
          this.sendlocation=null
        },
        viewAssessment(assessmentID){
          document.getElementById("closeUpdate").click()
          router.push({name: 'AssessmentDetails', params: {id: assessmentID }})
        },
        handleDatesSet: function(arg) {
          let dateD=arg.start
          this.$emit('updateDates', dateD.getMonth()+1)
          },
        handleEventClick: function(arg) {
          if(this.scopeCheck('view_slot_details')){
            this.slotInfo= this.slots.find(slot => slot.id === parseInt(arg.event.id))
            document.getElementById("yy").click()
          }
          
    },
    handleSelect: function(arg){
      
        this.$emit('bulkSelect' ,arg)
        
    },
        handleDateClick: function(arg) {
        if(this.scopeCheck('create_slot')){
          if(arg.view.type ==="dayGridMonth"){
          document.getElementById("ww").click()
        this.nameDisplay = arg.dateStr
        }
        }
        
    },
        timeUpdate(id){
        let newSlot= {
          "ele":this.sendELE,
          "ope":this.sendOPE,
          "location":this.sendlocation
          }
        SlotsServices.updateSlot(id,newSlot).then(()=>{
          Swal.fire({
          icon:"success",
          title:"Success!",
          text:"Slot Updated",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,})

          this.$emit('updateSlot')
          this.$forceUpdate
          document.getElementById("closeUpdate").click()
        })
        .catch((error)=>{
          let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
          this.$emit('updateSlot')
          this.$forceUpdate
        })

      
    },
    timeDelete(id){
      Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to delete this slot?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                SlotsServices.deleteSlot(id).then(()=>{
          Swal.fire({
          icon:"success",
          title:"Success!",
          text:"Slot Deleted!",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,})

          this.$emit('updateSlot')
          this.$forceUpdate
          document.getElementById("closeUpdate").click()
        }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,
            error_api:error.response.config.url,
            error_message:message,
            email:user.email
           })
				}
			})
      
      this.$emit('updateSlot')
          this.$forceUpdate
          document.getElementById("closeUpdate").click()
                })
                            }
                        })
       
       

      
    },
    timeSend(){
      let timeNow= ""
      if(this.timeToSend.hours < 10){
        timeNow= "0"+this.timeToSend.hours +":"+this.timeToSend.minutes
      }
      else{
        timeNow= this.timeToSend.hours +":"+this.timeToSend.minutes
      }
      let checkEmpty = this.slots.filter(cus => cus.start_time+cus.date === timeNow+":00"+this.nameDisplay )
      if (checkEmpty.length === 0){
        let newSlot= {
          "date":this.nameDisplay,
          "start_time":timeNow,
          "ele":this.sendELE,
          "ope":this.sendOPE,
          "location":this.sendlocation
        }
        SlotsServices.postSlot(newSlot).then(()=>{
          Swal.fire({
          icon:"success",
          title:"Success!",
          text:"Added slot to " + timeNow + " on " + this.nameDisplay,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,})

          this.$emit('updateSlot')
          this.$forceUpdate
          document.getElementById("close").click()
        })
        .catch((error)=>{
          let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
          this.$emit('updateSlot')
          this.$forceUpdate
        })

      }
      this.timeToSend=null
    },
       timeEnd(){
      this.timeToSend=null
    },
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	
  },
  created(){
    watchEffect(()=>{
      if(window.innerWidth < 720){
      this.calendarOptions.headerToolbar= {
        left:null,
        right:null,
        center:'title'
        };
      }
      this.calendarOptions.events=[]
      this.slots.forEach((slot)=>{
      let bgColor= "#00ADEE";
      let displayer = 'block';
      let txtColor = "#FFFFFF";
      if(slot.assessment)
      {
        switch(slot.assessment.status){
        case "ongoing":
          bgColor="#d6d8d9"
          txtColor="#1b1e21"
          break;
        case "pending":
          bgColor="#1D4176"
          txtColor = "#FFFFFF";
          break;
        case "booked":
          bgColor="#d6d8d9"
          txtColor="#1b1e21"
          break;
        case "completed":
          bgColor="#d6d8d9"
          txtColor="#1b1e21"
          break;
        case "sealed":
          bgColor="#d6d8d9"
          txtColor="#1b1e21"
          break;
        case "disputed":
          bgColor="#d97706"
          txtColor = "#FFFFFF";
          break;
        case "cancelled":
          bgColor="#990c53"
          txtColor="#FFFFFF"
      }
      }
  
      let nameSlot ="Open Slot";
      if(slot.assessment)
      {
        nameSlot = slot.assessment.customer
      }
      let dateOne= new Date(slot.date+" "+slot.start_time)
      this.calendarOptions.events.push({id:slot.id, title:nameSlot, date:dateOne.toISOString(), backgroundColor:bgColor, textColor:txtColor, display:displayer})
    })
    
    })
    this.getsamples()
    this.getcustomers()
    this.getaddresses()
    
  }
}
</script>

<style scoped>

.fc .fc-toolbar-title {
    font-size: 0.75em;
    margin: 0;
}
.dp__select {
  --dp-success-color: #0E2D5B;
}
</style>