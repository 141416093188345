<template>
<Transition name="slide-fade" appear>

    <div class="px-2 sm:px-6 lg:px-4 text-t3 dark:text-t3-nightText ">
        <div class="justify-between flex items-center">
            <h2 class="text-2xl font-bold sm:text-3xl dark:text-white">
                Assessments
            </h2>
            <div class=" space-x-2">
                <router-link :to="{name: 'Assessments'}" class="border-none"><button class=" font-medium border-0 bg-t3 p-3 text-white rounded-md hover:text-white hover:bg-t3-secondary">Card View</button></router-link>
            </div>
        </div>
        <button id="vv" type="button" class="hidden" data-bs-toggle="modal" data-bs-target="#modallo">
        </button>
        <!-- Actual Calendar Component Call -->

        <div class="grid 2xl:grid-cols-3 min-w-full mb-6 mt-4 border rounded p-7 bg-white dark:bg-t3-nightCard shadow">
            <div class="min-h-64 2xl:col-span-2">
                <div class="flex justify-between mb-4">
                    <div class="my-2">
                        <h1 style="color:#00ADEE"><span style="background-color:#00ADEE" class="inline-block w-4 h-4"></span> Available </h1>
                    </div>
                    <div class="my-2">
                        <h1 style="color:#1D4176"><span style="background-color:#1D4176" class="inline-block w-4 h-4"></span> Pending </h1>
                    </div>
                    <div class="my-2">
                        <h1 style="color:#d97706"><span style="background-color:#d97706" class="inline-block w-4 h-4"></span> Disputed </h1>
                    </div>
                    <div class="my-2">
                        <h1 style="color:#990c53"><span style="background-color:#990c53" class="inline-block w-4 h-4"></span> Cancelled </h1>
                    </div>
                    <div class="my-2">
                        <button id="bulko" v-if="scopeCheck('create_bulk_slots')" type="button" data-bs-toggle="modal" data-bs-target="#modallis" class=" font-medium  border-t3 border p-3 text-t3 flex rounded-md hover:border-opacity-0 hover:text-white hover:bg-t3-secondary" >
				Add Bulk Slots</button>
                    </div>
                </div>
                <Calendar :slots="allSlots" @updateDates="updateDate" @bulkSelect="bulkSelect" @updateSlot="updateSlots" defoTime="01:00"/>
         
                <!-- Modal For Bulk Add -->
                <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modallis" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5 v-if="onSelected" class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel"> Bulk adding for {{ bulk.start_date }} to {{ bulk.end_date }}                        </h5>
                        <button type="button" @click="closeBulk()"
                        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center relative p-4">
                        
                                    <Datepicker v-model="bulk.start_time" timePicker placeholder="Select Start Time" />
                                    <Datepicker v-model="bulk.end_time" timePicker placeholder="Select End Time" class="my-8"/>
                                    <Datepicker v-if="!onSelected"   @update:modelValue="updateDateSlots" v-model="date" placeholder="Select Date Range" range :presetRanges="presetRanges" class="my-8" />
                        <select  v-model="bulk.ope" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                        <option :value="null">Select OPE</option>
                        <option v-for="staff in OPE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                        </select>
                        <select  v-model="bulk.ele" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                        <option :value="null">Select ELE</option>
                        <option v-for="staff in ELE" :key="staff.id" :value="staff.id">{{staff.full_name}}</option>
                        </select>
                        <select  v-model="bulk.location" class="h-12 my-8 form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                            <option disabled :value="null">Select Location</option>
                            <option v-for="location in addresses"  :value="location.id" :key="location.id">{{location.name}}</option>
                        </select>
                        <Datepicker v-model="bulk.start_break" timePicker placeholder="Select Break Start Time" class="my-8"/>
                        <Datepicker v-model="bulk.end_break" timePicker placeholder="Select Break End Time" class="my-8"/>
                        <div class="form-floating my-8">
                            <input v-model="bulk.break" type="text" class="form-control w-full  px-3 py-1.5 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                            placeholder="First Name" id="floatingInput1" />
                            <label for="floatingInput1" class="text-gray-700" >Break<span class="text-red-600">*</span></label>
                        </div>

                            </div>
                            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button @click="closeBulk()" type="button" id="closeBulk"
                                class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal">
                                Close
                                </button>
                                <button type="button"
                                @click="bulkAdd" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                Add Slots
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>



        <!-- Modal for new Request -->
        

                    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modallo" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">
                        </h5>
                        <button type="button"
                        class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center relative p-4">
                        <label for="datepick" class="text-sm  text-gray-400 mt-8">Highlighted dates have available slots </label>
                        <Datepicker id="datepick" @update:modelValue="newSlots" v-model="slotDate" :min-date="new Date()" :enableTimePicker="false" :highlight="highlighted" datePicker placeholder="Select Date"  class="mb-8  form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                        <select  v-model="req.slot" class="h-12  form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                            <option v-if="freeSlot.length === 0" disabled :value="null">No slots available for the selected date</option>
                             <option v-if="freeSlot.length !== 0" disabled :value="null">Select Slot</option>
                            <option v-for="slot in freeSlot"  :value="slot.id" :key="slot.id">Date:{{new Date(slot.date).toLocaleDateString('en-GB',{year:'numeric',month:'short',day:'numeric'})}} Time:{{slot.start_time}}</option> 
                        </select>
                        <div  class="relative text-gray-600 my-6 focus-within:text-gray-400">
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg v-if="!req.customer" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6 text-gray-500">
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                        <svg v-if="req.customer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 text-t3-secondary">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                    </span>
                    <input id="search" v-if="!req.customer" :class="{ 'searcher': customerOnly.length >0 }" v-model="search" @input="debCheck()" aria-placeholder="Search" placeholder="Search for Customer and Select from List"
                        class="py-2 h-12 pl-10 block w-full border-solid outline-none border border-gray-300 rounded  focus:text-gray-700"
                        type="search" name="search" required autocomplete="search" />
                    <input @click="reSearch"  v-if="req.customer" type="text"  v-model="customerName" aria-placeholder="Search" placeholder="Search"
                        class="py-2 h-12 pl-10 block w-full border-solid border outline-none cursor-pointer hover:bg-red-200 hover:text-red-600 border-gray-300 bg-white rounded focus:text-gray-700"
                         />
                    <div id="list" v-if="!req.customer" :class="{ 'lister': customerOnly.length === 0 }" class="absolute z-10 m-0 flex flex-col items-center hidden justify-center bg-white rounded rounded-t-none w-full border-gray-300 border-t-0 border">
                    <button v-for="customer in customerRange" class="text-t3 hover:bg-gray-300 hover:text-white w-full"  @click="suggestcustomer(customer.id,customer.full_name)" :key="customer.id">{{customer.full_name}}</button>
                    </div>
                    </div>
                            </div>
                            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button type="button" id="reqClose"
                                class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal">
                                Close
                                </button>
                                <button type="button"
                                @click="ReqSend" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                Add Request
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>

                </div>
        <!-- Booked Requests -->

            <div  class="flex flex-col p-6 text-center mt-6 2xl:mt-0 2xl:text-left 2xl:border-l 2xl:ml-6 lg:ml-14 ">
                <h1 class=" text-t3 text-3xl font-semibold mb-2"> Customer Requests </h1>
                <p class="text-t3-mutedText text-md ">Here you will find the booking requests sent by the customers, upon confirmation an email will be sent to the customer</p>
                

                    <div class="text-lg text-center text-t3-mutedText my-auto" v-if="enquiries.length === 0 && openBookings.length === 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>There are currently no booking requests pending approval.</p>
                     </div>
                    <div v-for="booking in enquiries" :key="booking.id" @click="$router.push({name: 'addReq', params: {id: booking.id }})" class="group cursor-pointer text-center md:text-left md:flex w-full py-4 justify-between items-center noxu">
                        <div class="md:w-48">
                            <h2 class="text-t3 text-lg font-semibold mb-2 truncate">{{booking.full_name}}</h2>
                            <p  class="text-t3-mutedText text-md ">Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{new Date(booking.slot_date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</p>
                            <p  class="text-t3-mutedText text-md ">Time:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{booking.slot_time}}</p>
                        </div>
                    </div>
                    <div class="flex py-3 items-center text-t3-mutedText text-sm">
                        <hr class="flex-auto">
                        <p>Returning Customers</p>
                        <hr class="flex-auto">
                    </div>
                    <div v-for="booking in openBookings" :key="booking.id" class="group text-center md:text-left md:flex w-full py-4 justify-between items-center noxu">
                        <div class="md:w-48">
                            <h2 class="text-t3 text-lg font-semibold mb-2 truncate">{{booking.customer_name}}</h2>
                            <p v-if="bookingSlot(booking.slot)" class="text-t3-mutedText text-md ">Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{new Date(bookingSlot(booking.slot).date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</p>
                            <p v-if="bookingSlot(booking.slot)" class="text-t3-mutedText text-md ">Time:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{bookingSlot(booking.slot).start_time}}</p>
                        </div>
                        <div v-if="scopeCheck('approve_request') || scopeCheck('decline_request')" class="boxu md:hidden p-2">
                            <button :value="booking.id" v-if="scopeCheck('approve_request')" @click="emailSent" class="text-sm font-semibold mb-2 mr-2 cursor-pointer" style="color:#22c55e">Confirm</button>
                            <br class="md:hidden">
                            <button :value="booking.id" v-if="scopeCheck('decline_request')" @click="emailNotSent" class="text-sm font-semibold mb-2 cursor-pointer" style="color:#ef4444">Decline</button>
                        </div>
                    </div>
                    <button @click="newRequestModal()" class=" w-48 m-auto mt-32 mb-8 rounded-lg font-medium p-3 border-t3 border text-t3 hover:text-white hover:bg-t3-secondary hover:border-t3-secondary block" >Add Request</button>
                
            </div>
        </div>
    
    </div>
</Transition>


</template>

<style scoped>
    .noxu:hover{
        background-color:#fafafa;
        padding-left:12px;
    }
    .noxu:hover .boxu{
        display:block
    }
    #search:focus+#list{
    display: flex;
}
#search:not(focus)+.lister{
    border:none;
}
.searcher:focus{
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
#list:hover{
    display: flex;
}
    
</style>

<script>
    
      import Calendar from '@/components/Calendar.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import addDays from 'date-fns/addDays';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { ref, watchEffect } from 'vue';
import {
mapActions,
mapGetters, mapState
} from 'vuex';
import BookingServices from "../services/BookingServices";
import SlotsServices from '../services/SlotsServices';
import ReportServices from '../services/ReportServices';
import UsersServices from '../services/UsersServices';
    export default {
        
        components: {
                Datepicker ,
                Calendar
            },
        data() {
            return {
                bulk:{
                    start_date:null,
                    start_time:null,
                    start_break:'12:00',
                    end_break:'13:00',
                    end_date:null,
                    end_time:null,
                    break:null,
                    location:null,
                    ele:null,
                    ope:null
                },
                enquiries:[],
                tab:"new",
                slotDate:null,
                displayDates:{
                    booked: 0,
                    ongoing:0,
                    pending:0,
                    completed:0,
                    cancelled:0
                },
                month: null,
                booking: true,
                req:{
                    customer:null,
                    slot:null
                },
                timeToSend:null,
                freeSlot:[],
                customerName:null,
                search:'',
                onSelected:false,
            }
        },
        setup(){
            
		const date = ref();
		const presetRanges = ref([
        { 
          label: 'Today', 
          range: [new Date(), new Date()] },
        { 
          label: 'This month', 
          range: [startOfMonth(new Date()), endOfMonth(new Date())] },
        {
          label: 'Last month',
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
        },
        { 
          label: 'This year', 
          range: [startOfYear(new Date()), endOfYear(new Date())] },
      ]);
		const highlightedDates = ref([
			addDays(new Date(), 1),
			addDays(new Date(), 2),
			addDays(new Date(), 3),
		])

		return {
			date,
			highlightedDates,
			presetRanges
		}
        },
        
        methods: {
            ...mapActions({
                getslots: 'Slots/getSlotsForDate',
                searchcustomers: 'Users/searchCustomers',
                getallslots: 'Slots/getAllSlots',
                getbooked: 'Requests/getBookings',
                getcustomers: 'Users/getAllCustomers',
                getstaff: 'Users/getStaff',
                getaddresses: 'Settings/getAllAddresses',
            }),
            updateDateSlots(event){
        this.bulk.start_date = new Date(event[0]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
        this.bulk.end_date = new Date(event[1]).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
		if(this.bulk.end_date ==="1970-01-01"){
			this.bulk.end_date = null
		}
		
        },
        bulkSelect(arg){
            this.onSelected=true
            let date_1 = new Date(arg.start);
            let date_2 = new Date(arg.end);
                let difference = date_2.getTime() - date_1.getTime();
                let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            if(this.scopeCheck('create_bulk_slots') && TotalDays>1){
              this.bulk.start_date=arg.startStr
              this.bulk.end_date=arg.endStr
          document.getElementById("bulko").click()
                
        }},
        closeBulk(){
            this.onSelected=false
            this.bulk = {
                    start_date:null,
                    start_time:null,
                    start_break:'12:00',
                    end_break:'13:00',
                    end_date:null,
                    end_time:null,
                    break:null,
                    location:null,
                    ele:null,
                    ope:null
                }
        },
        bulkAdd(){
            if(this.bulk.end_time && this.bulk.start_time && this.bulk.break && this.bulk.location && this.bulk.start_date && this.bulk.end_date){
                let mins= this.bulk.start_time.minutes
                let mine= this.bulk.end_time.minutes
                let minbs= this.bulk.start_break.minutes
                let minbe= this.bulk.end_break.minutes
                if(this.bulk.start_time.hours < 10){
                    this.bulk.start_time= "0"+this.bulk.start_time.hours +":"+this.bulk.start_time.minutes

                    }
                    else{
                        this.bulk.start_time= this.bulk.start_time.hours +":"+this.bulk.start_time.minutes
                    }
                    if(this.bulk.end_time.hours < 10){
                    this.bulk.end_time= "0"+this.bulk.end_time.hours +":"+this.bulk.end_time.minutes
                    }
                    else{
                        this.bulk.end_time= this.bulk.end_time.hours +":"+this.bulk.end_time.minutes
                    }
                    if(mins < 10){
                        this.bulk.start_time=this.bulk.start_time+'0'
                    }
                    if(mine < 10){
                        this.bulk.end_time=this.bulk.end_time+'0'
                    }
                    if(this.bulk.start_break.hours < 10){
                    this.bulk.start_break= "0"+this.bulk.start_break.hours +":"+this.bulk.start_break.minutes

                    }
                    else{
                        this.bulk.start_break= this.bulk.start_break.hours +":"+this.bulk.start_break.minutes
                    }
                    if(this.bulk.end_break.hours < 10){
                    this.bulk.end_break= "0"+this.bulk.end_break.hours +":"+this.bulk.end_break.minutes
                    }
                    else{
                        this.bulk.end_break= this.bulk.end_break.hours +":"+this.bulk.end_break.minutes
                    }
                    if(minbs < 10){
                        this.bulk.start_break=this.bulk.start_break+'0'
                    }
                    if(minbe < 10){
                        this.bulk.end_break=this.bulk.end_break+'0'
                    }
                    this.bulk.break = parseInt(this.bulk.break)
                SlotsServices.bulkSlot(this.bulk).then(()=>{
              Swal.fire({
              icon:"info",
              title:"Request Sent",
              text:"Bulk adding is in progress please wait a couple minutes and then refresh.",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 6000,})
    
              this.updateSlots()
              this.$forceUpdate
              this.onSelected=false
              document.getElementById("closeBulk").click()
            })
            .catch((error)=>{
                let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
          let user= JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
          
          
          console.log("Error: "+message)
				}
			})
              this.updateSlots()
              this.$forceUpdate
            })
            }
            else{
                Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all required the fields.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
            }
        },  updateDate(month){
                console.log(month)
            },
            newSlots(data){
            if(data){
                this.slotDate=data
                let dat= this.slotDate.toISOString()
                this.getslots(dat.substr(0,10)).then(()=>{
                    if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    }}
                )
            }  
        },
            ReqSend(){
                if(this.req.customer && this.req.slot)
                {
                    let sendingObj = {
                        customer:this.req.customer,
                        slot:this.req.slot,
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    BookingServices.newBooking(getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Booking request sent",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                        this.getbooked()
                        this.updateSlots()
                        this.freeSlot = this.slots.filter(slot => slot.assessment === null)
                        this.$forceUpdate()
                        document.getElementById("reqClose").click()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
          let user= JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
          
          
          console.log("Error: "+message)
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
            emailSent(event){
                BookingServices.confirmBookings(event.target.value).then(() => {
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"The assessment is now booked and ready",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                       this.$forceUpdate()
                       this.getbooked()
                       this.getallslots()
                    })
                    .catch(error => {
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
          let user= JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email }) 
          console.log("Error: "+message)
				}
			})
                    })
            },
            emailNotSent(event){
                BookingServices.declineBookings(event.target.value).then(() => {
                       this.$forceUpdate()
                       this.getbooked()
                                Swal.fire({
                                icon:"info",
                                title:"Declined",
                                text:"The booking was declined and discarded",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                    })
                    .catch(error => {
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {
          let user= JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
            },
            updateSlots(){
                this.getallslots()
                this.freeSlot = this.allSlots.filter(slot => slot.assessment === null)
                this.$forceUpdate
            },
            bookingCustomer(sentID){
			return this.customers.find(cus => cus.id === sentID)},
            bookingSlot(sentID){
			return this.allSlots.find(cus => cus.id === sentID)},	
            newRequestModal(){
                document.getElementById("vv").click()
            },
            suggestcustomer(id,name){
                this.customerName = name
                this.req.customer = id
                this.customerOptions()
            },
            reSearch(){
                this.search = null
                this.customerName = null
                this.req.customer = null
                this.customerOptions()
            },
            debCheck: _.debounce(function(){
            this.customerName = this.search
        if(this.search === ""|| this.search === null)
        {
            this.searchcustomers({input:null, number:15})
        }
        else{
            
            this.searchcustomers({input:this.search, number:100})
        }
        }, 700),
			scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))

				if(user.is_company){
                    
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}		
            
        },
        computed: {
            openBookings(){
                return this.booked.filter(b => b.status === "sent" )
            },
            customerRange(){
        if(this.customerOnly)
        {return this.customerOnly.slice(0,7)}
        else{
            return []
        }
      },

            ...mapState({
                slots: state => state.Slots.slotD,
                allSlots: state => state.Slots.slots,
                booked: state => state.Requests.bookings,
                customers: state => state.Users.customers,
                addresses: state => state.Settings.addresses
            }),
            ...mapGetters('Users', [
            'customerOnly',
            'OPE',
            'ELE'
            ])
        },

        created() {
            if(this.slotDate){
                let dat= this.Assess.date.toISOString()
                this.getslots(dat.substr(0,10))
                }
                else{
                    this.slots=null
                }
                if(this.slots){
                    this.freeSlot=this.slots.filter(slot => slot.assessment === null)
                    
                }
                this.getallslots()
            watchEffect(() => {
            if(this.allSlots){
                this.highlighted=[]
                    this.allSlots.filter(slot => slot.assessment === null).forEach((s)=>{
                        this.highlighted.push(new Date(s.date))
                    })
                    }
            
            })
            
            this.getbooked()
            this.getcustomers()
            this.getstaff()
            this.getaddresses()
            
            UsersServices.getEnquiries().then((response)=>{
				this.enquiries = response.data
			})

}
    }
</script>